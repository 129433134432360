//
// === BUTTONS ===
//

//
// - Base Button -
//

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    margin-top: 20px;
    border-radius: 0;
    padding: 14px;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1;
    text-align: left;
    transition: all .2s ease-in-out;

    + .btn {
        margin-top: 10px;
    }

    i {
        float: right;
        margin-left: 10px;
    }

    &.has-icon-left i {
        float: left;
        margin: 0 10px 0 0;
    }

    &.has-icon-far-right i {
        float: right;
    }

    @include media-breakpoint-up(md) {
        margin-top: 30px;

        i {
            margin-left: 15px;
        }

        &.btn--lg {
            padding: 20px;
            font-size: 1.8rem;

            i {
                line-height: 1.8rem;
            }
        }
    }

    //
    // - Block Button -
    //

    &.btn--block {
        position: relative;
        width: 100%;
    }

    //
    // - Button Modifiers -
    //

    &.btn--primary {
        background-color: $ebony-clay;
        color: $white;

        &:hover {
            background-color: $btn-primary-hover;
        }
    }

    &.btn--secondary {
        background-color: $supernova;
        color: $ebony-clay;

        &:hover {
            background-color: $btn-secondary-hover;
        }
    }

    &.btn--default {
        background-color: $seashell;
        color: $ebony-clay;

        &:hover {
            background-color: $btn-default-hover;
        }
    }

    &.btn--section {
        margin-top: 10px;
        padding: 30px;
        background-color: $white;
        font-size: 1.6rem;
        color: $ebony-clay;
        box-shadow: 0 3px 0 0 $shadow-seashell;
    }

    &.btn--scroll {
        display: inline-block;
        position: relative;
        left: 50%;
        margin-top: 16px;
        padding-bottom: 7px;
        font-size: 1.7rem;
        transform: translateX(-50%);
    }

    &.btn--back {
        margin-top: 0;
        padding-left: 0;

         &.btn--back--blog-detail {
             margin-left: 15px;
         }
    }

    &.btn--reset {
        line-height: 2.2rem;
    }

    &.btn--locate {
        padding-left: 20px;
        padding-right: 20px;

        i {
            margin: 0;
        }
    }

    &.btn--filter {
        position: relative;
        padding: 12px 7px;
        border-left: 8px solid $white;
        border-radius: 2px;
        font-size: 1rem;
        color: $white;
        box-shadow: 0 1px 4px -1px $shadow-maps-btn;

        &.is-new {
            background-color: $ebony-clay;

            &:hover {
                background-color: $btn-primary-hover;
            }
        }

        &.is-on-hold {
            background-color: $peel;

            &:hover {
                background-color: $btn-peel-hover;
            }
        }

        &.is-pending {
            background-color: $pomegranate;

            &:hover {
                background-color: $btn-pomgranate-hover;
            }
        }

        &.is-processed-positive {
            background-color: $lima;

            &:hover {
                background-color: $btn-lima-hover;
            }
        }

        &.is-processed-neutral {
            background-color: $supernova;
            color: $ebony-clay;

            &:hover {
                background-color: $btn-secondary-hover;
            }
        }
    }

    &.btn--link {
        padding: 0;
        text-decoration: underline;
        opacity: .61;

        &:hover {
            opacity: 1;
        }
    }
}

//
// - Button with a Link next to it -
//

.btn-link-combo {
    margin-top: 30px;
    display: flex;
    align-items: center;

    .btn {
        margin-top: 0;
    }

    p {
        margin-left: 10px;
    }

    a {
        .btn-play,
        .btn-play::after {
            transition: background-color .2s ease-in-out, border-color .2s ease-in-out;
        }

        .btn-play {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            width: 31px;
            height: 31px;
            margin-left: 10px;
            border: 2px solid $ebony-clay;
            border-radius: 100%;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 0 5px 10px;
                border-color: transparent transparent transparent $ebony-clay;
                transform: translate(-50%, -50%);
            }
        }

        &:hover {
            .btn-play {
                background-color: $ebony-clay;

                &::after {
                    border-left-color: $white;
                }
            }
        }
    }
}
