//
// === ICONOGRAPHY ===
//

//
// - Icon Font -
//

@font-face {
    font-family: 'verkeerslicht-icons';
    src:    url('#{$base-font-path}verkeerslicht-icons.ttf?1qoqmp') format('truetype'),
            url('#{$base-font-path}verkeerslicht-icons.woff?1qoqmp') format('woff'),
            url('#{$base-font-path}verkeerslicht-icons.svg?1qoqmp#verkeerslicht-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

//
// - Variables -
//

$iconfont: 'verkeerslicht-icons';

//
// - Default -
//

i {
    // sass-lint:disable no-important
    font-family: 'verkeerslicht-icons' !important;
    // sass-lint:enable no-important
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.4rem;
    transition: color .2s ease-in-out;
}

//
// - Icons -
//

.icon-arrow-right::before {
  content: '\e900';
}

.icon-arrow-left::before {
  content: '\e901';
}

.icon-arrow-down::before {
  content: '\e902';
}

.icon-arrow-up::before {
  content: '\e903';
}

.icon-eye::before {
  content: '\e904';
}

.icon-clock::before {
  content: '\e905';
}

.icon-search::before {
  content: '\e906';
}

.icon-download::before {
  content: '\e907';
}

.icon-data::before {
  content: '\e908';
}

.icon-graph::before {
  content: '\e909';
}

.icon-marker::before {
  content: '\e910';
}

.icon-phone::before {
  content: '\e911';
}

.icon-mail::before {
  content: '\e912';
}

.icon-kvk::before {
  content: '\e913';
}

.icon-close::before {
  content: '\e914';
}

.icon-location::before {
  content: '\e915';
}

.icon-slow::before {
  content: '\e916';
}

.icon-fast::before {
  content: '\e917';
}

.icon-alert::before {
  content: '\e918';
}

.icon-cone::before {
  content: '\e919';
}

.icon-check::before {
  content: '\e920';
}

.icon-calendar::before {
  content: '\e921';
}

.icon-marker-solid::before {
  content: '\e922';
}

.icon-lms-dashboard::before {
  content: '\e923';
}

.icon-lms-pages::before {
  content: '\e924';
}

.icon-lms-blog::before {
  content: '\e925';
}

.icon-lms-question::before {
  content: '\e926';
}

.icon-lms-menus::before {
  content: '\e927';
}

.icon-lms-devices::before {
  content: '\e928';
}

.icon-lms-reports::before {
  content: '\e929';
}

.icon-lms-places::before {
  content: '\e930';
}

.icon-lms-users::before {
  content: '\e931';
}

.icon-lms-messages::before {
  content: '\e932';
}

//
// - USP Icons -
//

.usp-icons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    li {
        font-size: 1.1rem;
        line-height: 1.4rem;

        i {
            display: block;
            margin-bottom: 10px;
            font-size: 2.3rem;
            line-height: 1;
            color: $supernova;
        }
    }

    @include media-breakpoint-up(md) {
        width: 450px;
        margin-top: 30px;

        li {
            font-size: 1.8rem;
            line-height: 2.1rem;

            i {
                margin-bottom: 20px;
                font-size: 3.2rem;
            }
        }
    }
}
