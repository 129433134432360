//
// === GOOGLE MAP (the ones in the modal(s), not the one on the reports page) ===
//

.pac-container {
    z-index: 999999;
}

.gllpLatlonPickerMobileReportDirect,
.gllpLatlonPickerDesktopReportDirect,
.gllpLatlonPickerDesktopCompleteReport {
    display: none;

    .google-map {
        width: 100%;
        height: 0;
        max-height: 0;
        transition: max-height .5s cubic-bezier(.5, .69, .14, 1);
    }

    @include media-breakpoint-down(md) {
        &.is-visible {
            display: block;

            .google-map {
                height: 250px;
                max-height: 500px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        display: block;

        .google-map {
            height: 500px;
            max-height: 1000px;
        }
    }
}

.gm-style-iw {
    .btn.btn--secondary {
        margin-top: 20px;

        + .btn.btn--secondary {
            margin-top: 20px;
            margin-left: 10px;
        }
    }
}
