//
// === FOOTER ===
//

.site-footer {
    padding-bottom: 67px;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    section {
        padding: 30px;

        &.footer-top {
            background-color: $ebony-clay;
            color: $white;

            p {
                font-size: 1rem;
            }

            a {
                margin-left: 10px;

                img {
                    width: auto;
                    height: 20px;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        section {
            &.footer-top {
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;

        section {
            &.footer-top {
                padding-top: 75px;
                padding-bottom: 60px;

                .h2 {
                    margin-bottom: 15px;
                    color: $white;
                }
            }

            &.footer-bottom {
                background-color: $white;

                ul {
                    li {
                        display: inline-block;
                        font-size: 1.4rem;
                        color: $footer-bottom;

                        a {
                            padding-left: 20px;
                            color: $footer-bottom;
                            transition: color .2s ease-in-out;
                        }

                        + li a {
                            margin-left: 20px;
                            border-left: 1px solid $footer-bottom;
                        }

                        a:hover {
                            color: $ebony-clay;
                        }
                    }
                }

                .logo-developer {
                    transition: opacity .2s ease-in-out;

                    img {
                        width: auto;
                        height: 21px;
                    }

                    &:hover {
                        opacity: .5;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .container {
            padding-left: 90px;
            padding-right: 90px;
        }
    }
}

.btn.btn--footer {
    display: inline-block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 29px 30px;
    font-size: 1.6rem;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
