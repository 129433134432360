//
// === ALERT ===
//

.alert {
    position: fixed;
    top: 52px;
    width: 100%;
    padding: 15px 20px;
    font-size: 1.6rem;
    color: $white;
    z-index: 101;

    &.alert-dismissible {
        transition: opacity .5s cubic-bezier(.05, .69, .14, 1);

        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 20px;
            font-size: 3rem;
            line-height: 5rem;
            color: $white;
            opacity: .5;
            transition: opacity .1s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }

        &.is-fading-out {
            opacity: 0;
        }
    }

    &.alert-success {
        background-color: $alert-success;
    }

    &.alert-info {
        background-color: $alert-info;
    }

    &.alert-warning {
        background-color: $alert-warning;
    }

    &.alert-danger {
        background-color: $alert-danger;
    }

    @include media-breakpoint-up(md) {
        top: 87px;
    }
}
