//
// === APP DEMO ===
//

.app__demo {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50%;
    height: 530px;
    background-image: url('../../dist/images/home/home_iphone.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    transform: translateY(calc(-50%));

    .slick-slider {
        padding: 55px 50px 0 40px;
    }

    .slick-dots,
    .badges {
        transform: translateX(calc(-50% - 5px));
    }

    .slick-dots {
        bottom: 50px;
    }

    .badges {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 226px;

        a img {
            height: 32px;
        }
    }

    @include media-breakpoint-up(xl) {
        width: 35%;
    }

    @include media-breakpoint-up(lg) {
        top: 25px;
        height: 700px;

        .slick-slider {
            padding: 77px 70px 0 56px;
        }
    }

    @include media-breakpoint-up(xl) {
        top: 0;
        height: 660px;

        .slick-slider {
            padding: 71px 64px 0 53px;
        }
    }
}
