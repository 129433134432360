//
// === REPORTS ===
//

//
// - App Loader -
//

app-loader {
    .icon-alert {
        font-size: 2rem;
        padding: 12px;
    }

    span.text-alert {
        font-size: 1.8rem;
    }
}

//
// - Reports Legend -
//

.reports-legend {
    li {
        display: block;
        position: relative;
        padding-left: 25px;
        font-family: $font-primary;
        font-size: 1.5rem;

        + li {
            margin-top: 10px;
        }

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 15px;
            height: 15px;
            border-radius: 100%;
            transform: translateY(-50%);
        }

        &.is-new::before {
            background-color: $ebony-clay;
        }

        &.is-on-hold::before {
            background-color: $peel;
        }

        &.is-rejected::before {
            background-color: $pomegranate;
        }

        &.is-accepted::before {
            background-color: $lima;
        }

        &.is-pending::before {
            background-color: $pomegranate;
        }

        &.is-processed-neutral::before {
            background-color: $supernova;
        }

        &.is-processed-positive::before {
            background-color: $lima;
        }
    }
}

.reports-container {
    .reports-map {
        height: 100vh;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        max-width: 100%;
        padding: 0;

        aside,
        .reports-map {
            position: fixed;
            height: calc(100vh - 87px);
        }

        aside {
            position: fixed;
            width: 50%;
            background-color: $white;

            .search-reports {
                position: fixed;
                width: 50%;
                z-index: 1;
            }

            .reports-list {
                height: calc(100vh - 171px);
                margin-top: 84px;
                padding-bottom: 50px;
                overflow-y: scroll;
            }
        }

        .reports-map {
            left: 50%;
            width: calc(100% - 50%);
        }

        .reports-filter {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            top: 0;
            right: 10px;
            //width: 100%;
            padding: 10px 0 0;

            .btn {
                margin: 0 0 10px 10px;
            }

            input {
                display: none;

                &:checked + .btn {
                    background-color: $white;
                    color: $ebony-clay;

                    &.is-new {
                        border-color: $ebony-clay;
                    }

                    &.is-on-hold {
                        border-color: $peel;
                    }

                    &.is-pending {
                        border-color: $pomegranate;
                    }

                    &.is-processed-positive {
                        border-color: $lima;
                    }

                    &.is-processed-neutral {
                        border-color: $supernova;
                    }

                    &:hover {
                        background-color: $seashell;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        $aside-lg-width: 440px;

        aside {
            width: $aside-lg-width;

            .search-reports {
                width: $aside-lg-width;
            }
        }

        .reports-map {
            left: $aside-lg-width;
            width: calc(100% - #{$aside-lg-width});
        }
    }
}

//
// - Reports Search -
//

.search-reports {
    position: relative;
    margin: 25px 0;

    .form-control {
        width: 100%;
        border: 0;
        padding: 25px 60px 25px 30px;
        font-size: 1.4rem;
        box-shadow: 0 3px 0 0 $shadow-seashell;
    }

    i {
        position: absolute;
        top: 50%;
        right: 25px;
        font-size: 2.2rem;
        transform: translateY(-50%);
    }

    @include media-breakpoint-up(md) {
        margin: 0;

        .form-control {
            background-color: $wild-sand;
            font-size: 1.8rem;
            box-shadow: none;
        }
    }
}

//
// - Reports List -
//

.reports-list {
    .report {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0 -30px;
        padding: 30px 30px 30px 45px;
        line-height: 1.7rem;

        .report__content {
            font-size: 1.4rem;
        }

        .report__marker {
            position: absolute;
            top: 30px;
            left: 15px;
            width: 20px;
            text-align: center;

            &::before {
                position: absolute;
                top: 7px;
                left: 0;
                font-family: $iconfont;
                content: '\e922';
                font-size: 3rem;
            }

            .report__id {
                position: relative;
                top: 1px;
                color: $white;
            }
        }

        .report__description,
        .report__timestamp {
            display: block;
            font-size: 1.1rem;
        }

        .report__timestamp {
            margin-top: 10px;
            opacity: .61;
        }

        &:nth-child(even) {
            background-color: $reports-odd-even-bg;
        }

        &.is-new .report__marker::before {
            color: $ebony-clay;
        }

        &.is-on-hold .report__marker::before {
            color: $peel;
        }

        &.is-rejected .report__marker::before {
            color: $pomegranate;
        }

        &.is-accepted .report__marker::before {
            color: $lima;
        }

        &.is-pending .report__marker::before {
            color: $pomegranate;
        }

        &.is-processed-positive .report__marker::before {
            color: $lima;
        }

        &.is-processed-neutral {
            .report__marker {
                &::before {
                    color: $supernova;
                }

                .report__id {
                    color: $ebony-clay;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .report {
            flex-direction: row;
            justify-content: space-between;
            margin: 0;
            padding-left: 65px;

            .report__content {
                .report__marker {
                    left: 30px;
                }
            }

            .report__timestamp {
                flex-shrink: 0;
                margin: 0 0 0 10px;
            }
        }
    }
}
