.breadcrumbs {
    padding: 15px 0;
    z-index: 2;

    ul {
        li {
            a {
                font-size: 1.4rem;
                color: $ebony-clay;

                i {
                    margin-right: 10px;
                }
            }

            &:not(:first-child) {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        width: calc(100% - 30px);
        padding: 0;
        background-color: $ebony-clay;
        overflow: hidden;

        ul {
            display: flex;

            li {
                position: relative;

                a {
                    display: block;
                    position: relative;
                    padding: 10px 5px 10px 30px;
                    font-size: 1.3rem;
                    color: $breadcrumb-text;
                    text-decoration: none;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 100%;
                        top: 0;
                        border-style: solid;
                        border-width: 20px 0 20px 20px;
                        border-color: transparent;
                        z-index: 1;
                    }

                    &:hover {
                        color: $white;
                    }
                }

                &:not(:first-child) {
                    display: block;
                }

                &:nth-child(1) a {
                    padding-left: 80px;
                }

                &:nth-child(odd) a {
                    &::after {
                        border-left-color: $ebony-clay;
                    }
                }

                &:nth-child(even) a {
                    background-color: $breadcrumb-odd;

                    &::after {
                        border-left-color: $breadcrumb-odd;
                    }
                }

                &:nth-child(n+1) a {
                    pointer-events: none;
                    cursor: default;

                    &:hover {
                        color: inherit;
                    }
                }
            }
        }
    }
}
