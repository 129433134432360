/* ==========================================================================
   Remodal's default mobile first theme
   ========================================================================== */

/* Default theme styles for the background */

.remodal-bg.remodal-is-opening,
.remodal-bg.remodal-is-opened {
  filter: blur(3px);
}

/* Default theme styles of the overlay */

.remodal-overlay {
  background: rgba(43, 46, 56, 0.9);
}

.remodal-overlay.remodal-is-opening,
.remodal-overlay.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodal-overlay.remodal-is-opening {
  animation-name: remodal-overlay-opening-keyframes;
}

.remodal-overlay.remodal-is-closing {
  animation-name: remodal-overlay-closing-keyframes;
}

/* Default theme styles of the wrapper */

.remodal-wrapper {
  padding: 10px 10px 0;
}

/* Default theme styles of the modal dialog */

.remodal {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding: 35px;

  transform: translate3d(0, 0, 0);

  color: #2b2e38;
  background: #fff;
}

.remodal.remodal-is-opening,
.remodal.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodal.remodal-is-opening {
  animation-name: remodal-opening-keyframes;
}

.remodal.remodal-is-closing {
  animation-name: remodal-closing-keyframes;
}

/* Vertical align of the modal dialog */

.remodal,
.remodal-wrapper:after {
  vertical-align: middle;
}

/* Keyframes
   ========================================================================== */

@keyframes remodal-opening-keyframes {
  from {
    transform: scale(1.05);

    opacity: 0;
  }
  to {
    transform: none;

    opacity: 1;

    filter: blur(0);
  }
}

@keyframes remodal-closing-keyframes {
  from {
    transform: scale(1);

    opacity: 1;
  }
  to {
    transform: scale(0.95);

    opacity: 0;

    filter: blur(0);
  }
}

@keyframes remodal-overlay-opening-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes remodal-overlay-closing-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Media queries
   ========================================================================== */

@media only screen and (min-width: 641px) {
  .remodal {
    max-width: 700px;
  }
}

/* IE8
   ========================================================================== */

.lt-ie9 .remodal-overlay {
  background: #2b2e38;
}

.lt-ie9 .remodal {
  width: 700px;
}
