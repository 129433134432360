//
// === PAGINATION ===
//

.pagination {
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 10px 0;
        background-color: $white;

        li {
            display: inline-block;

            a {
                display: block;
                border: 1px solid $seashell;
                background-color: $seashell;
                font-size: 2rem;
                padding: 0 10px;
                line-height: 2.8rem;
                text-align: center;
                color: $ebony-clay;

                &:hover {
                    background-color: $btn-default-hover;
                }
            }

            &.active a {
                border-color: $ebony-clay;
                background-color: $ebony-clay;
                font-style: bold;
                color: $white;

                &:hover {
                    background-color: $btn-primary-hover;
                }
            }

            &.disabled a {
                background-color: transparent;
                cursor: not-allowed;
            }
        }
    }
}
