﻿//
// === COLORS ===
//

$black: #000;
$white: #fff;
$gallery: #f0f0f0;

//
// - Primary Colors -
//

$catskill-white: #e6eaf2;
$ebony-clay: #242e42;
$supernova: #ffcf00;
$seashell: #f1f1f1;

.text-supernova {
    color: $supernova;
}

//
// - Secondary Colors -
//

$lima: #79c410;
$peel: #f90;
$pomegranate: #f7412d;

//
// - Traffic Light Colors -
//

$deep-fir: #030;
$apple: #52b848;
$cola: #430;
$lightning: #febe10;
$temptress: #300;
$cardinal: #cb242b;

//
// - Shadow Colors -
//

$shadow-ebony-clay: rgba(0, 0, 0, .1);
$shadow-supernova: rgba(255, 207, 0, 1);
$shadow-seashell: rgba(230, 230, 230, 1);
$shadow-maps-btn: rgba(0, 0, 0, .298039);

//
// - Header and Nav Colors -
//

$mobile-navitem-border: #2b3547;
$desktop-header-bg: rgba(255, 255, 255, .97);
$desktop-navitem-active-bg: rgba(240, 240, 240, .52);
$desktop-subnavitem-border: #ebecee;

//
// - Button and Link Colors -
//

$btn-primary-hover: lighten($ebony-clay, 10%);
$btn-secondary-hover: lighten($supernova, 10%);
$btn-default-hover: lighten($seashell, 2.5%);
$btn-lima-hover: lighten($lima, 2.5%);
$btn-peel-hover: lighten($peel, 10%);
$btn-pomgranate-hover: lighten($pomegranate, 10%);
$footer-bottom: rgba(36, 46, 66, .5);

//
// - Breadcrumb Colors -
//

$breadcrumb-odd: #2d3649;
$breadcrumb-text: #a6a6a6;

//
// - Form Colors -
//

$input-disabled-color: #a9a9a9;
$input-disabled-border: #ededed;
$input-border: darken($input-disabled-border, 30%);
$radio-problem-border: #ebebeb;
$radio-problem-checked-bg: #f2f3f4;
$calendar-icon: #aaaeb6;
$checkbox: #e4e6e8;

//
// - Accordion Colors -
//

$accordion-odd-bg: #fbfbfb;
$accordion-even-bg: #f7f7f7;

//
// - Alert Colors -
//

$alert-success: lighten($lima, 0%);
$alert-info: lighten($ebony-clay, 0%);
$alert-warning: lighten($peel, 0%);
$alert-danger: lighten($pomegranate, 0%);

//
// - Other Colors -
//

$wild-sand: #f6f6f6;
$concrete: #f2f2f2;
$persona-function: #9d7f00;
$testimonial-border: #f8f8f8;
$reports-odd-even-bg: #fafafa;
$reports-legend-bg: rgba(255, 255, 255, .5);
$article-time: #cfcfcf;

//
// - LMS Colors -
//

$nav-top-bg: #2b3446;
$nav-top-text: #929aa2;
$nav-aside-item: #f7c902;
$counter-type: #a5acb4;
$input-border-lms: #dfdfdf;
$input-text-lms: rgba(43, 52, 70, .61);
$badge-bg: #f15822;
$page-header-hover: #f9f9f9;
$footer-border: #d8d7d8;
