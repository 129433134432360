//
// === SLICK SLIDER ===
//

.slick-slider {
    user-select: none;
    touch-action: pan-y;
    // sass-lint:disable no-vendor-prefixes
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    // sass-lint:enable no-vendor-prefixes

    .slick-list,
    .slick-track {
        transform: translate3d(0, 0, 0);
    }

    .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;

        .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;

            .slick-slide {
                float: left;
                height: 100%;
                min-height: 1px;
                outline: none;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.slick-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
        position: relative;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            padding: 5px;
            color: transparent;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: $ebony-clay;
                transition: background-color .2s ease-in-out;
            }

            &:hover,
            &:focus {
                outline: none;
            }

            &:hover::before {
                background-color: $btn-default-hover;
            }
        }

        &.slick-active button::before {
            background-color: $supernova;
        }
    }
}
