//
// === CARD ===
//

.card {
    padding: 30px;

    //
    // - Card Modifiers -
    //

    &.card--white {
        background-color: $white;
    }

    &.card--blue {
        background-color: $ebony-clay;
        color: $white;

        .h3 {
            color: $white;
        }
    }
}
