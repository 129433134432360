//
// === CONTACT ===
//

.form--contact {
    margin-top: 20px;
    text-align: right;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}

.section--contact {
    address {
        position: relative;
        padding-left: 15px;

        &::before {
            position: absolute;
            top: 2px;
            left: 0;
            font-family: $iconfont;
            content: '\e910';
            font-size: 1.1rem;
            color: $supernova;
        }

        p {
            margin: 0;
        }
    }

    .contact-info {
        li {
            position: relative;
            padding-left: 20px;

            &::before {
                position: absolute;
                font-family: $iconfont;
                color: $supernova;
            }

            &:nth-child(1),
            &:nth-child(2) {
                &::before {
                    left: 2px;
                }
            }

            &:nth-child(1) {
                &::before {
                    top: 0;
                    content: '\e911';
                    font-size: 1.1rem;
                }
            }

            &:nth-child(2),
            &:nth-child(3) {
                &::before {
                    top: 4px;
                }
            }

            &:nth-child(2) {
                &::before {
                    content: '\e912';
                    font-size: .7rem;
                }
            }

            &:nth-child(3) {
                &::before {
                    left: 0;
                    content: '\e913';
                    font-size: .6rem;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .contact-info {
            li {
                padding-left: 30px;

                &:nth-child(1) {
                    &::before {
                        top: 5px;
                        font-size: 1.6rem;
                    }
                }

                &:nth-child(2),
                &:nth-child(3) {
                    &::before {
                        top: 11px;
                    }
                }

                &:nth-child(2)::before {
                    font-size: 1.1rem;
                }

                &:nth-child(3)::before {
                    font-size: .9rem;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .row {
            align-items: flex-start;
        }

        address {
            margin-top: 18px;
            padding-left: 30px;

            &::before {
                font-size: 2rem;
            }
        }

        .contact-info {
            margin-top: 40px;
        }
    }
}
