//
// === FORMS ===
//

//
// - Form Control -
//

.form-control {
    border: 1px solid $input-border;
    padding: 15px 40px 15px 15px;
    background-color: $white;
    font-size: 1.3rem;
    line-height: 1.7rem;
    transition: border-color .2s ease-in-out;

    &:not([type='checkbox']) {
        &:hover,
        &:focus {
            border-color: $ebony-clay;
        }
    }

    &[disabled],
    &[readonly] {
        border-color: $input-disabled-border;
        cursor: not-allowed;

        &:hover,
        &:focus {
            border-color: $input-disabled-border;
        }
    }

    @include media-breakpoint-up(md) {
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 1.6rem;
        line-height: 3.4rem;
    }
}

//
// - Coordinates -
//

.coordinate-wrapper {
    position: relative;

    label {
        position: absolute;
        top: 50%;
        left: 15px;
        font-size: 1.6rem;
        line-height: 3.4rem;
        color: $input-disabled-color;
        z-index: 1;
        transform: translateY(-50%);
    }

    .form-control {
        padding-right: 15px;
        padding-left: 120px;
        color: $input-disabled-color;
    }
}

//
// - Radio Buttons -
//

.radio {
    &.radio--problem {
        input[type='radio'] {
            display: none;
        }

        input[type='radio']:checked {
            + label {
                border: {
                    top: 1px solid $ebony-clay;
                    bottom: 1px solid $ebony-clay;
                    right: 1px solid $ebony-clay;
                }

                &::before {
                    background-color: $ebony-clay;
                }
            }
        }

        label {
            display: flex;
            gap: 8px;
            position: relative;
            margin-left: 8px;
            border: 1px solid $radio-problem-border;
            padding: {
                top: 20px;
                bottom: 20px;
                left: 16px;
                right: 16px;
            }
            align-items: center;
            min-height: 56px;
            font-size: 1.4rem;
            cursor: pointer;
            transition: background-color .1s ease-in-out;

            &::before,
            &::after,
            i {
                position: absolute;
            }

            &::before {
                content: '';
                display: block;
                top: -1px;
                left: -8px;
                width: 8px;
                height: calc(100% + 2px);
            }

            &::after,
            span {
                top: 50%;
                transform: translateY(-50%);
            }

            &::after {
                opacity: 0;
                right: 15px;
                font-family: $iconfont;
                content: '\e920';
                transition: opacity .2s ease-in-out;
            }

            i {
                left: 10px;
                font-size: 2rem;
                line-height: 2rem;
            }

            &:hover {
                background-color: $radio-problem-checked-bg;
            }

            @include media-breakpoint-up(md) {
                min-height: 68px;
            }
        }

        input[type='radio']:checked + label {
            background-color: $radio-problem-checked-bg;

            &::after {
                opacity: 1;
            }
        }

        &.radio--long-red {
            label {
                &::before {
                    background-color: $pomegranate;
                }
            }
        }

        &.radio--unsafe {
            label {
                &::before {
                    background-color: $peel;
                }
            }
        }

        &.radio--short-green {
            label {
                &::before {
                    background-color: $lima;
                }
            }
        }

        &.radio--short-dark-blue {
            label {
                &::before {
                    background-color: $ebony-clay !important;
                }
            }
        }

        &.radio--other {
            input[type='radio']:checked {
                + label {
                    border: {
                        top: 1px solid $ebony-clay;
                        bottom: 1px solid $ebony-clay;
                        right: 1px solid $ebony-clay;
                    }

                    &::before {
                        background-color: $ebony-clay;
                    }
                }
            }

            label {
                i {
                    color: $ebony-clay;
                }

                &::before {
                    background-color: $catskill-white;
                }
            }
        }
    }
}

//
// - Checkbox -
//

label.checkbox {
    display: block;
    margin-top: 20px;
    cursor: pointer;

    span {
        display: block;
        position: relative;
        padding-left: 25px;
        font-size: 1.2rem;

        &::before,
        &::after {
            content: '';
            position: absolute;
        }

        &::before {
            top: 2px;
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid $checkbox;
            border-radius: 2px;
            background-color: $white;
            transition: border-color .2s ease-in-out, background-color .2s ease-in-out;
        }

        &::after {
            top: 6px;
            left: 2px;
            font-family: $iconfont;
            font-size: .9rem;
        }
    }

    input[type='checkbox'] {
        display: none;

        &:checked + span {
            &::before {
                background-color: $checkbox;
            }

            &::after {
                content: '\e920';
            }
        }
    }

    &:hover span::before {
        border-color: $ebony-clay;
    }

    @include media-breakpoint-up(md) {
        margin-top: 30px;

        span {
            font-size: 1.4rem;
        }
    }
}

//
// - Datepicker -
//

::-webkit-calendar-picker-indicator {
    opacity: 1;

    &::after {
        display: block;
        font-family: $iconfont;
        content: '\e921';
        color: $calendar-icon;
    }
}

//
// - Select -
//

.select-wrapper {
    position: relative;

    &::after {
        position: absolute;
        top: 50%;
        right: 18px;
        font-family: $iconfont;
        content: '\e902';
        font-size: 1.5rem;
        transform: translateY(-50%);
        pointer-events: none;
    }

    select {
        width: 100%;
    }

    // &:focus {
    //     &::after {
    //         content: '\e902';
    //     }
    // }
}

//
// - Form Section -
//

.form {
    p,
    .from-to {
        font-size: 1.4rem;
        line-height: 1.9rem;
    }
}

.form__section {
    padding: 30px;

    + .form__section {
        border-top: 1px solid $seashell;
    }

    .h3 + p {
        margin-bottom: 20px;
    }

    .select-wrapper {
        margin-top: 7px;
    }
}

.form-group {
    position: relative;
    margin-bottom: 7px;

    &.radio {
        margin-bottom: 12px;
    }

    .form-control,
    .btn {
        width: 100%;
        margin-top: 0;
    }

    .btn {
        position: relative;
        margin-top: 0;
    }

    + .btn {
        margin-top: 5px;
    }

    [class^='col-'] {
        + [class^='col-'] {
            .form-control,
            .btn {
                margin-top: 7px;
            }
        }
    }

    .form-control + [class^='icon-'],
    .btn i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .form-control + i {
        right: 30px;
        font-size: 2rem;
    }

    .btn i {
        right: 15px;
        font-size: 1.4rem;
    }
}

.form-subsection {
    margin-top: 30px;
}

.col-v-align-items {
    display: flex;
    align-items: center;
}

@include media-breakpoint-up(md) {
    .form {
        p,
        .from-to {
            font-size: 14px;
            line-height: 1.2;
        }
    }

    .form__section {
        padding: 40px;

        .h3.extra-margin {
            margin-top: 10px;
        }

        .h3 + p {
            margin-bottom: 30px;
        }

        select {
            margin-top: 0;
        }
    }

    .form-subsection {
        &.subsection--time {
            margin-top: 0;
        }
    }

    .form-group {
        margin-bottom: 14px;

        &.radio {
            margin-bottom: 14px;
        }

        + .btn {
            margin-top: 15px;
        }

        [class^='col-']:not(.col-xs-12) {
            + [class^='col-'] {
                .form-control,
                .btn {
                    margin-top: 0;
                }
            }
        }
    }

    .col-md-p-left-0 {
        padding-left: 0;
    }

    .col-md-p-left-less {
        padding-right: 7px;
    }

    .col-md-p-right-less {
        padding-left: 7px;
    }
}

//
// - Search -
//

.search-wrapper {
    display: flex;
    margin-top: 10px;

    input {
        flex-grow: 1;
    }

    .btn {
        margin-top: 0;

        + .btn {
            margin-top: 0;

        }
    }
}
