.post-block {
    margin: 15px 0 30px;

    // Children
    a {
        display: block;
        height: 100%;
        padding-bottom: 40px;
        position: relative;
    }

    .post-block__img {
        height: 160px;
        width: 100%;
        margin-bottom: 15px;
        box-shadow: 0 3px 0 0 $shadow-supernova;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    p {
        margin-top: 10px;
    }

    footer {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
        margin-top: 15px;
        border-top: 1px solid $gallery;
        padding-top: 10px;
        position: absolute;
        bottom: 0;
        left: 0;

        i {
            color: $supernova;
        }

        .post__metadata {
            li {
                display: inline-block;
                font-size: .9rem;

                i {
                    margin-right: 5px;
                }

                + li {
                    margin-left: 10px;
                }
            }
        }
    }

    // Responsive
    @include media-breakpoint-up(md) {
        height: 100%;

        .post-block__img {
            overflow: hidden;

            img {
                transition: transform .5s cubic-bezier(.5, .69, .14, 1);
            }
        }

        footer .post__metadata {
            li {
                font-size: 1.2rem;

                + li {
                    margin-left: 40px;
                }
            }
        }

        &:hover {
            .post-block__img {
                img {
                    transform: scale(1.05);
                }
            }

            .icon-arrow-right {
                color: $ebony-clay;
            }
        }

        //
        // - Featured Post -
        //

        &.is-featured a {
            display: flex;

            > * {
                width: 50%;
            }

            .post-block__img {
                height: 100%;
                margin-bottom: 0;
                box-shadow: none;
            }

            footer {
                position: relative;
                margin-top: 60px;
            }

            &:hover {
                .icon-arrow-right {
                    color: $white;
                }
            }
        }
    }
}
