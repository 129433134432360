//
// === TESTIMONIALS ===
//

.testimonials {
    li {
        position: relative;
        padding: 30px 0;
        overflow: hidden;
        border-bottom: 1px solid $testimonial-border;

        blockquote {
            p {
                font-weight: 300;
                font-size: 1.4rem;
                line-height: 2rem;
            }

            footer {
                margin-top: 10px;
                opacity: .57;

                cite {
                    font-size: .8rem;
                }
            }
        }

        figure {
            img {
                display: none;
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -26px;
            width: 100%;
            height: 26px;
            border-radius: 100%;
            box-shadow: 0 0 13px 0 $shadow-ebony-clay;
        }

        &:last-child {
            border: 0;

            &::after {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(md) {
        li {
            display: flex;
            justify-content: space-between;
            padding: 75px 0;

            blockquote {
                p {
                    font-size: 2.2rem;
                    line-height: 3.7rem;
                }

                footer cite {
                    font-size: 1.6rem;
                }
            }

            figure {
                margin-left: 50px;

                img {
                    display: block;
                    width: 137px;
                    height: auto;
                    border: 8px solid $seashell;
                    border-radius: 100%;
                }
            }
        }
    }
}
