//
// === SCROLL ANIMATION ===
//

.scroll-animation {
    display: inline-block;
    vertical-align: -8px;
    margin-left: 20px;

    .scroll-animation__down-icons {
        width: 2px;
        margin: 0 auto 6px;
        margin-bottom: 6px;

        span {
            display: block;
            height: 2px;
            background-color: $ebony-clay;
            animation: fade 1s infinite;
            animation-direction: alternate;

            + span {
                margin-top: 3px;
            }

            &:nth-child(1) {
                animation-delay: .1s;
            }

            &:nth-child(2) {
                animation-delay: .2s;
            }

            &:nth-child(3) {
                animation-delay: .3s;
            }
        }
    }

    .scroll-animation__mouse {
        width: 18px;
        height: 31px;
        border: 1px solid $ebony-clay;
        border-radius: 10px;

        .mouse__scrollWheel {
            width: 2px;
            height: 5px;
            margin: 5px auto;
            background: $ebony-clay;
            animation: scrollWheel 1.2s ease infinite;
        }
    }

    @keyframes fade {
        0% {
            opacity: 1;
        }

        50% {
            opacity: .5;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fade {
        0% {
            opacity: 0;
        }

        50% {
            opacity: .5;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes scrollWheel {
        0% {
            opacity: 1;
            transform: translateY(0);
        }

        100% {
            opacity: 0;
            transform: translateY(6px);
        }
    }
}
