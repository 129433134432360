//
// === ANGULAR MODAL ===
//

modal-overlay {
    visibility: hidden;

    * {
        outline: none;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $ebony-clay;
        opacity: 0;
        transition: opacity .5s cubic-bezier(.05, .69, .14, 1);

        &.in {
            opacity: .67;
        }
    }

    .modal {
        width: 600px;
        position: absolute;
        top: 97px;
        right: 10px;

        .modal-ng__header,
        .modal-ng__body {
            padding: 25px 30px;
        }

        .modal-ng__header {
            position: relative;
            background-color: $ebony-clay;

            .modal-title {
                font-weight: 800;
                font-size: 1.8rem;
                line-height: normal;
                color: $white;
            }

            .close {
                position: absolute;
                top: 50%;
                right: 20px;
                width: 35px;
                height: 35px;
                padding: 0 10px;
                font-size: 2.8rem;
                color: $white;
                transform: translateY(-50%);
            }
        }

        .modal-ng__body {
            display: flex;
            max-height: 400px;
            background-color: $white;
            overflow-y: scroll;

            > div {
                width: 100%;

                > div > span {
                    padding-left: 15px;
                    font-weight: 800;
                    font-size: 1.8rem;
                    line-height: normal;
                }
            }

            ul {
                margin-top: 20px;

                li {
                    padding: 20px 15px;
                    font-size: 1.4rem;
                    line-height: 2rem;

                    span {
                        display: block;

                        &:first-child {
                            opacity: .61;
                        }
                    }

                    &:nth-child(odd) {
                        background-color: $reports-odd-even-bg;
                    }
                }

                + div {
                    margin-top: 25px;
                    padding: 14px;
                    background-color: $supernova;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 1;
                    color: $ebony-clay;
                    cursor: pointer;
                    transition: background-color .1s ease-in-out, color .2s ease-in-out;

                    i {
                        float: right;
                        margin-left: 10px;
                    }

                    &:hover {
                        background-color: $btn-secondary-hover;
                    }
                }
            }
        }

        .modal-ng__footer {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        visibility: visible;
    }
}
