//
// === HEADER ===
//

.site-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
	height: $site-header-height-mobile;
	background-color: $white;
	box-shadow: 0 5px 10px -2px $shadow-ebony-clay;
	z-index: 101;

	.header__logo {
		display: block;
		padding: 12px 13px;
		background-color: $ebony-clay;

		svg {
			width: auto;
			height: 26px;

			.light1 {
				fill: $cardinal;
				animation: redlight 12s 1;
			}

			.light2 {
				fill: $lightning;
				animation: amberlight 12s 1;
			}

			.light3 {
				fill: $apple;
				animation: greenlight 12s 1;
			}

			.light1,
			.light2,
			.light3 {
				animation-duration: 4s;
			}

			@keyframes greenlight {
				0% {
					fill: $deep-fir;
				}

				5% {
					fill: $apple;
				}

				45% {
					fill: $apple;
				}

				50% {
					fill: $deep-fir;
				}

				100% {
					fill: $deep-fir;
				}
			}
			@keyframes amberlight {
				0% {
					fill: $cola;
				}

				45% {
					fill: $cola;
				}

				55% {
					fill: $lightning;
				}

				60% {
					fill: $cola;
				}

				100% {
					fill: $cola;
				}
			}
			@keyframes redlight {
				0% {
					fill: $temptress;
				}

				55% {
					fill: $temptress;
				}

				60% {
					fill: $cardinal;
				}

				95% {
					fill: $cardinal;
				}

				100% {
					fill: $temptress;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		align-items: stretch;
		height: $site-header-height-tablet;
		padding-right: 0;
		background-color: $desktop-header-bg;

		&.bg-non-transparent {
			background-color: $white;
		}

		.header__logo {
			padding: 19px 25px 22px;

			svg {
				height: 46px;
			}
		}
	}

	.toggle-nav {
		padding: 15px;
		font-family: $font-headings;
		font-size: 1.2rem;

		span,
		span::after,
		span::before {
			width: 20px;
			height: 3px;
			background-color: $ebony-clay;
			backface-visibility: hidden;
			transition: all .5s cubic-bezier(.5, .69, .14, 1);
		}

		span {
			display: inline-block;
			position: relative;
			vertical-align: 2px;
			margin-left: 10px;

			&::after,
			&::before {
				content: '';
				position: absolute;
				left: 0;
			}
		}

		span::before {
			top: -7px;
		}

		span::after {
			top: 7px;
		}

		&.can-close {
			span {
				background-color: transparent;

				&::after,
				&::before {
					background-color: $supernova;
				}

				&::before {
					transform: rotate(45deg) translate(5px, 5px);
				}

				&::after {
					transform: rotate(-45deg) translate(5px, -5px);
				}
			}
		}

		@include media-breakpoint-up(md) {
			padding-right: 40px;
		}

		@include media-breakpoint-up(lg) {
			padding-right: 60px;
		}

		@media screen and (min-width: 1280px) {
			display: none;
		}
	}
}
