//
// === LAYOUT ===
//

//
// - Body and Page Wrapper -
//

body,
.page-wrapper {
	background-color: $gallery;
}

//
// - Container Override -
//

@media (min-width: 1200px) {
	.container {
	    max-width: 1192px;
	}
}

//
// - Main -
//

main {
	padding-top: $site-header-height-mobile;
	padding-bottom: 65px;
	opacity: 0;
	transform: scale(.95);
	transition: all .5s ease-in-out;

	&::before {
		content: '';
		visibility: hidden;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $ebony-clay;
		opacity: 0;
		z-index: 10;
		transition: opacity .5s cubic-bezier(.05, .69, .14, 1);
	}

	&.overlay-visible {
		&::before {
			visibility: visible;
			opacity: .67;
		}
	}

	&.page-transition {
		opacity: 1;
		transform: scale(1);
	}

	@include media-breakpoint-up(md) {
		padding-top: $site-header-height-tablet;
		padding-bottom: 110px;
		opacity: 1;
		transform: scale(1);

		.container {
			position: relative;
		}
	}
}
