//
// === TYPOGRAPHY ===
//

//
// - Fonts -
//

// Roboto
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,800');

// Avenir Next
@font-face {
    font-family: 'Avenir Next Medium';
    src:    url('#{$base-font-path}AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
            url('#{$base-font-path}AvenirNext-Medium.woff') format('woff'),
            url('#{$base-font-path}AvenirNext-Medium.ttf') format('truetype'),
            url('#{$base-font-path}AvenirNext-Medium.svg#AvenirNext-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Bold';
    src:    url('#{$base-font-path}AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),
            url('#{$base-font-path}AvenirNext-Bold.woff') format('woff'),
            url('#{$base-font-path}AvenirNext-Bold.ttf') format('truetype'),
            url('#{$base-font-path}AvenirNext-Bold.svg#AvenirNext-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

//
// - Variables -
//

$font-primary:  'Roboto', Verdana, Helvetica;
$font-navmenu:  'Avenir Next Medium', Verdana, Helvetica;
$font-headings: 'Avenir Next Bold', Verdana, Helvetica;

//
// - Default -
//

html,
body {
    font-size: 62.5%; // For rem
    font-family: $font-primary;
    text-size-adjust: 100%;
    line-height: normal;
    color: $ebony-clay;
}

body,
button,
input,
select,
textarea {
    text-rendering: optimizeLegibility;
    // sass-lint:disable no-vendor-prefixes
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga', 'kern';
    // sass-lint:enable no-vendor-prefixes
}

//
// - Headers -
//

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: normal;
    color: $ebony-clay;
}

.h1,
.h2 {
    margin-bottom: 10px;
    font-family: $font-headings;
    font-size: 2.8rem;
    line-height: 3rem;
    letter-spacing: -2px;
}

.h3 {
    margin-bottom: 7px;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.h4 {
    font-size: 1.4rem;
    line-height: 2rem;
}

// .h5 {
//     margin-bottom: 7px;
//     font-weight: 700;
//     font-size: 1.4rem;
//     line-height: 2rem;;
// }

@include media-breakpoint-up(md) {
    .h1,
    .h2 {
        margin-bottom: 20px;
        font-size: 6rem;
        line-height: 6rem;
    }

    .h2 {
        &.has-leading-zero {
            position: relative;
            z-index: 1;

            .leading-zero {
                position: absolute;
                top: -45px;
                left: -2px;
                font-size: 12.7rem;
                color: $supernova;
                opacity: .15;
                z-index: -1;
            }
        }
    }

    .h3 {
        margin-bottom: 15px;
        font-size: 1.8rem;
        line-height: 2.6rem;
    }

    .h4 {
        font-size: 2.4rem;
        line-height: 3.4rem;
    }

    // .h5 {
    //     margin: 60px 0 15px;
    //     font-weight: 800;
    //     font-size: 1.8rem;
    // }
}

//
// - Smalls -
//

small {
    display: block;
    margin-bottom: 20px;
    font-size: 1.2rem;
    line-height: 1.5rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 40px;
        font-size: 1.8rem;
        line-height: 2.4rem;
    }
}

//
// - Paragraphs -
//

p {
    margin-bottom: 20px;
    font-size: 1rem;
    line-height: 1.6rem;

    a {
        font-weight: 500;
        text-decoration: underline;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

@include media-breakpoint-up(md) {
    p {
        margin-bottom: 30px;
        font-size: 1.8rem;
        line-height: 2.8rem;
    }

    header + p {
        line-height: 3.4rem;
    }
}

//
// - Lists -
//

ol,
ul {
    &.list {
        margin-bottom: 20px;

        li {
            padding-left: 20px;
            font-size: 1.4rem;
            line-height: 2.1rem;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 40px;

            li {
                padding-left: 35px;
                font-size: 1.8rem;
                line-height: 3.5rem;
            }
        }
    }
}

ul {
    &.list {
        list-style: disc outside none;
    }
}

ol {
    &.list {
        list-style: decimal-leading-zero inside none;

        li + li {
            margin-top: 10px;
        }

        &.list--leading-zero {
            counter-reset: item;
            list-style-type: none;

            li {
                position: relative;
                padding-left: 75px;

                &::before {
                    content: counter(item, decimal-leading-zero) ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    counter-increment: item;
                    font-family: $font-headings;
                    font-size: 5rem;
                    line-height: 5rem;
                    color: $supernova;
                    opacity: .15;
                }

                p {
                    margin-top: 5px;
                }
            }
        }
    }
}
