//
// === NAVIGATION ===
//

//
// - Mobile Navigation -
//

.nav-mobile {
    $mobile-nav-width: 230px;
    position: fixed;
    top: 0;
    right: -$mobile-nav-width;
    width: $mobile-nav-width;
    height: 100%;
    padding: 10px 20px 20px;
    background-color: $ebony-clay;
    overflow: scroll;
    transition: transform .5s cubic-bezier(.05, .69, .14, 1);

    &.big-z-index {
        z-index: 100;
    }

    > ul {
        flex-direction: column;

    	> li {
            display: block;
            border-bottom: 1px solid $mobile-navitem-border;

            &:nth-of-type(3),
            &:last-of-type {
                display: none;
            }

            a,
            span {
                display: block;
                padding: 10px 0;
                font-family: $font-navmenu;
                font-size: 1.6rem;
                color: $white;
        	}

            span {
                cursor: pointer;
            }

            &.has-sub {
                .nav__sub {
                    position: relative;
                    max-height: 0;
                    opacity: 0;
                    text-indent: 15px;
                    z-index: -1;
                    transition: max-height .5s cubic-bezier(.05, .69, .14, 1), opacity .1s ease-in-out;

                    a {
                        font-weight: 300;
                    }

                    &.is-visible {
                        max-height: 130px;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
    	}
    }

    + .page-wrapper {
        transition: transform .5s cubic-bezier(.05, .69, .14, 1);
    }

    @media screen and (max-width: 1279px) {
        &.is-open {
            transform: translateX(-230px);

            + .page-wrapper {
                position: fixed;
                right: 0;
                left: 0;
                transform: translate(-$mobile-nav-width);
            }
        }
    }
}

//
// - Desktop Navigation -
//

.nav-desktop {
    display: none;

    > ul {
        display: flex;
        height: 100%;

        > li {

            &:nth-child(3),
            &:last-child {
                display: block;
            }

            a,
            span,
            button {
                display: block;
                color: $ebony-clay;
                transition: background-color .1s ease-in-out;
            }

            > a,
            > span,
            > button {
                padding: 0 20px;
                font-weight: 500;
                font-size: 1.8rem;
                line-height: $site-header-height-tablet;

                i {
                    line-height: $site-header-height-tablet;
                }

                &.btn {
                    display: inline-flex;
                    margin-top: 0;
                }
            }

            > span {
                cursor: default;
            }

            &.is-active {
                > a,
                > span,
                > button {
                    position: relative;
                    background-color: $desktop-navitem-active-bg;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 8px 8px 0;
                        border-color: $supernova transparent transparent;
                        transform: translateX(-50%);
                    }
                }
            }

            &:hover,
            &:focus {
                > a,
                > span
                > button {
                    background-color: $gallery;
                }
            }

            &.has-sub {
                position: relative;

                .nav__sub {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    min-width: 140%;
                    padding: 10px 20px;
                    background-color: $gallery;
                    transition: opacity .5s cubic-bezier(.05, .69, .14, 1);

                    li {
                        a {
                            position: relative;
                            padding: 15px 0 15px 30px;
                            font-size: 1.7rem;
                            transition: opacity .1s ease-in-out;

                            &::before {
                                position: absolute;
                                top: 50%;
                                left: 0;
                                font-family: $iconfont;
                                font-size: .7rem;
                                content: '\e900';
                                transform: translateY(-50%);
                            }

                            &:hover {
                                opacity: .5;
                            }
                        }

                        + li a {
                            border-top: 1px solid $desktop-subnavitem-border;
                        }
                    }
                }

                &:hover,
                &:focus {
                    span {
                        background-color: $gallery;
                    }

                    ul {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1280px) {
        display: block;
    }
}
