//
// === PERSONA ===
//

.persona {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 360px;
    height: 375px;
    background-image: url('../../dist/images/what-we-do/guus.png');
    background-size: cover;
    background-position: top center;

    .name-badge {
        display: flex;
        align-items: center;
        position: absolute;
        top: 120px;
        left: -30px;
        width: 143px;
        height: 143px;
        border-radius: 100%;
        background-color: $supernova;

        .name-badge__content {
            text-align: center;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.7rem;
            color: $ebony-clay;

            span {
                font-size: 1.3rem;
                color: $persona-function;
            }
        }
    }
}
