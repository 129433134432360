//
// === TABS ===
//

.tab-pane {
    display: none;

    &.is-active {
        display: block;
    }
}
