//
// === BANNER ===
//

.banner {
    background-size: cover;
    background-position: center center;

    &.banner--hero {
        height: 220px;

        &.banner--hero--default {
            background-image: url('../../assets/images/banners/banner_hero_home.png');
        }

        &.banner--hero--apps {
            background-image: url('../../assets/images/banners/banner_hero_apps.png');
        }

        &.banner--hero--about {
            background-image: url('../../assets/images/banners/banner_hero_about.png');
        }

        &.banner--hero--blog {
            background-image: url('../../assets/images/banners/banner_hero_blog.png');
        }

        &.banner--hero--faq {
            background-image: url('../../assets/images/banners/banner_hero_faq.png');
        }

        &.banner--hero--contact {
            background-image: url('../../assets/images/banners/banner_hero_contact.jpg');
        }

        &.banner--hero--registration {
            background-image: url('../../assets/images/banners/banner_apps-and-registration.png');
        }
    }

    &.banner--middle {
        display: flex;
        align-items: center;
        height: 665px;

        .h2 {
            margin-bottom: 0;
            color: $white;
        }

        &.banner--middle--apps {
            background-image: url('../../assets/images/banners/banner_apps-and-registration.png');
        }
    }

    &.banner--thanks {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        min-height: 235px;
        padding: 30px;
        background-image: url('../../assets/images/banners/banner_hero_home.png');
        text-align: center;
        color: $white;
        box-shadow: 0 3px 0 0 $shadow-supernova;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $ebony-clay;
            opacity: .87;
        }

        header {
            position: relative;

            .thanks__title,
            .thanks__subtitle {
                font-weight: 400;
            }

            .thanks__title {
                font-family: $font-headings;
                font-size: 4rem;
            }

            .thanks__subtitle {
                font-size: 2.3rem;
                line-height: 3rem;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &.banner--hero {
            height: 445px;

            &.banner--hero--blog-detail {
                margin-top: 0;

                + .container .breadcrumbs {
        			top: inherit;
        			left: inherit;
                }
            }
        }
    }
}
