//
// === ACCORDION ===
//

.accordion {
    .ac {
        position: relative;
        width: 100%;
        margin-bottom: 1px;
        overflow: hidden;

        &:nth-child(odd) {
            background-color: $accordion-odd-bg;
        }

        &:nth-child(even) {
            background-color: $accordion-even-bg;
        }

        input {
            position: absolute;
            opacity: 0;
            z-index: -1;
        }

        label,
        .ac-content p {
            padding-right: 40px;
            padding-bottom: 25px;
            padding-left: 15px;
        }

        label {
            position: relative;
            display: block;
            padding-top: 25px;
            font-size: 1.4rem;
            line-height: 2rem;
            cursor: pointer;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                background-color: $ebony-clay;
                line-height: 1rem;
                text-align: center;
                transition: opacity .2s ease-in-out;
            }

            &::before {
                top: 39px;
                right: 15px;
                width: 10px;
                height: 2px;
            }

            &::after {
                top: 35px;
                right: 19px;
                width: 2px;
                height: 10px;
            }
        }

        .ac-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height .5s cubic-bezier(.5, .69, .14, 1);

            p {
                opacity: .82;
            }
        }

        input[type='checkbox'] {
            &:checked {
                + label::after {
                    opacity: 0;
                }

                ~ .ac-content {
                    max-height: 1000px;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .ac {
            label,
            .ac-content p {
                padding-right: 106px;
                padding-bottom: 30px;
                padding-left: 25px;
            }

            label {
                padding-top: 30px;
                font-size: 1.8rem;
                line-height: 2.8rem;

                &::before {
                    top: 41px;
                    right: 50px;
                    width: 15px;
                    height: 3px;
                }

                &::after {
                    right: 56px;
                    width: 3px;
                    height: 15px;
                }
            }

            .ac-content {
                p {
                    font-size: 1.6rem;
                    line-height: 3rem;
                }
            }
        }
    }
}
