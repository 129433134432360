//
// === PAGE SECTION ===
//

.page__section {
	padding: 30px;

	.row {
		position: relative;

		&.post-row {
			align-items: stretch;

			.post-block-wrapper {
				margin-bottom: 20px;
			}
		}
	}

	header.has-btn {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;

		.h1,
		.btn {
			margin: 0;
		}

		@include media-breakpoint-up(md) {
			margin-bottom: 20px;
		}
	}

    //
    // - Page Section Modifiers -
    //

	&.section--white {
		background-color: $white;
		box-shadow: 0 3px 0 0 $shadow-seashell;
	}

	&:not(:first-of-type) {
		margin-top: 50px;
	}

    //
    // - Specific Sections -
    //

	&.section--first--home,
	&.section--content-page {
		margin-top: -90px;
	}

	&.section--first {
		&.section--complete-report {
			.form__section {
				padding-left: 0;
				padding-right: 0;

				&:first-of-type {
					padding-top: 0;
				}
			}
		}
	}

	&.section--fields {
		margin-top: 15px;

		.account-details {
			margin-top: 20px;
			font-size: 1.8rem;
			line-height: 2.8rem;
		}

		.link-forgot-password,
		.link-create-account {
			display: block;
			font-size: 1.2rem;
			line-height: 1.7rem;
			opacity: .4;
		}

		.link-forgot-password {
			margin-top: 5px;
			text-align: right;
		}

		.link-create-account {
			margin-top: 15px;
		}

		a:not(.btn) {
			display: block;
			margin-top: 0;
			text-decoration: underline;
		}
	}

	&.section--reports {
		padding-top: 0;
		padding-bottom: 0;
	}

	@include media-breakpoint-up(md) {
		padding-left: 80px;
		padding-right: 80px;

        .row {
            align-items: center;

            .app-screens {
                display: flex;
                justify-content: center;
                align-items: center;
            }

			&.post-row {
				align-items: stretch;

				.post-block-wrapper {
					margin-bottom: 40px;
				}
			}
        }

        //
        // - Page Section Modifiers -
        //

        &:not(:first-child) {
            margin-top: 70px;

			&.section--reports {
				margin-top: 0;
			}
        }

        //
        // - Specific Sections -
        //

        &.section--first {
            margin-top: -140px;

            &.section--first--home {
                padding-top: 160px;
                padding-bottom: 190px;
            }

            &.section--first--what-we-do {
				position: relative;
                padding-top: 150px;
                padding-bottom: 50px;
            }

            &.section--content-page {
                padding-top: 110px;
                padding-bottom: 50px;
            }

			&.section--faq {
                padding-top: 110px;
                padding-bottom: 65px;

				.faq__overview {
					margin-top: 30px;
				}

				.faq__user-submit {
					margin-top: 50px;
				}
            }

			&.section--contact {
				padding-top: 110px;
                padding-bottom: 50px;
			}

			&.section--complete-report {
				padding-top: 90px;
				padding-bottom: 90px;
			}
        }

		&.section--fields {
			padding-top: 90px;
			padding-bottom: 90px;

			.account-details {
				margin-top: 30px;
			}
		}

        &.section--home--how-it-works {
            padding-top: 110px;
            padding-bottom: 110px;
        }

        &.section--current-reports {
            padding-top: 130px;
            padding-bottom: 130px;

            .reports-legend {
                margin-top: 20px;
            }
        }

        &.section--home--stay-informed {
            padding-top: 100px;
            padding-bottom: 70px;

			.row {
				align-items: stretch;
			}
        }

		&.section--reports {
			padding: 0;
			box-shadow: none;

			li {
				cursor: pointer;
			}
		}

		&.section--first--apps,
		&.section--apps--safe-reporting {
			.img-phone {
				position: absolute;
			    top: 50%;
			    right: 0;
			}
		}

		&.section--first--apps {
			margin-bottom: 85px;
			padding-top: 160px;
			padding-bottom: 160px;

			.img-phone {
				width: 40%;
				transform: translateY(calc(-50% + 100px));

				@include media-breakpoint-up(lg) {
					width: 50%;
					transform: translateY(-50%);
				}

				@include media-breakpoint-up(xl) {
					width: auto;
					height: 595px;
					transform: translateY(calc(-50% - 65px));
				}
			}
		}

        &.section--apps--safe-reporting {
            margin-top: 110px;
            padding-top: 160px;
            padding-bottom: 160px;

			.img-phone {
				width: 50%;
				transform: translateY(-50%);

				@include media-breakpoint-up(lg) {
					width: 40%;
				}

				@include media-breakpoint-up(xl) {
					right: 80px;
					width: auto;
					height: 620px;
					transform: translateY(calc(-50% - 40px));
				}
			}
        }

        &.section--what-we-do--testimonials {
            padding-top: 75px;
            padding-bottom: 50px;
        }

        &.section--blog {
            padding-top: 100px;
            padding-bottom: 70px;

            .post-featured,
			.post-overview {
                margin-top: 30px;
            }
        }

		@include media-breakpoint-up(xl) {
			&.section--current-reports {
				background-image: url('../../assets/images/map.jpg');
	            background-position: top right;
	            background-repeat: no-repeat;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&.section--fields {
			.account-details {
				margin-top: 0;
			}
		}
	}
}

.section--thanks {
	margin-top: 15px;
	background-color: $white;

	footer {
		padding: 30px;

		.btn {
			width: 100%;
			margin-top: 0;

			+ .btn {
				margin-top: 10px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		position: relative;
		left: 50%;
		margin-top: 110px;
		transform: translateX(-50%);

		footer {
			display: flex;
			justify-content: center;

			.btn {
				width: auto;

				+ .btn {
					margin: 0 0 0 15px;
				}
			}
		}
	}
}
