//
// === RESET ===
//

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:focus {
    outline: 0;
}

a {
    color: $ebony-clay;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $ebony-clay;
    }
}

img {
    display: block;
}

ol,
ul {
    list-style: none;
    margin: 0;
}

address,
cite {
    font-style: normal;
}

button,
input:not([type='checkbox']),
textarea,
select,
.form-control {
    border-radius: 0;
    appearance: none;

    &:focus {
        box-shadow: none;
    }
}

button {
    outline: none;
    border: 0;
    background-color: transparent;
    font-size: inherit;
    cursor: pointer;

    &::-moz-focus-inner { // sass-lint:disable-line no-vendor-prefixes
        border: 0;
    }
}

input {
    border-radius: 0;

    // sass-lint:disable no-vendor-prefixes
    &::-ms-clear {
        display: none;
    }

    &::-webkit-search-cancel-button {
        display: none;
    }
}

select {
    cursor: pointer;

    &::-ms-expand {
        display: none;
    }
    // sass-lint:enable no-vendor-prefixes
}

textarea {
    height: 190px;
    resize: vertical;

    @include media-breakpoint-up(md) {
        height: 100px;
    }
}

fieldset {
    border: 0;
}
