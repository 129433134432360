//
// - Content Detail -
//

.content-page {
    .row {
        justify-content: center;
    }

    header {
        margin-bottom: 20px;

        time {
            position: relative;
            padding-left: 30px;
            font-family: $font-navmenu;
            color: $article-time;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 20px;
                height: 1px;
                background-color: $article-time;
                transform: translateY(-50%);
            }
        }

        p {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }

    .content-page__body {
        h2 {
            margin: 0;
            font-size: 1.4rem;
            line-height: 1.8rem;
            letter-spacing: normal;
        }

        p {
            font-size: 1.4rem;
            line-height: 2.1rem;
        }

        ol,
        ul {
            margin-bottom: 20px;

            li {
                padding-left: 20px;
                font-size: 1.4rem;
                line-height: 2.1rem;
            }
        }

        ul {
            list-style: none;

            li {
                position: relative;

                &::before {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    font-family: $iconfont;
                    font-size: .6rem;
                    content: '\e900';
                }
            }
        }

        ol {
            list-style: decimal-leading-zero inside none;

            li + li {
                margin-top: 10px;
            }
        }
    }

    footer {
        margin-top: 20px;
    }

    @include media-breakpoint-up(md) {
        header {
            margin-bottom: 40px;

            time {
                padding-left: 40px;

                &::before {
                    width: 30px;
                }
            }

            p {
                font-size: 2.2rem;
                line-height: 3.5rem;
            }
        }

        .content-page__body {
            h2 {
                font-size: 2.2rem;
                line-height: 3.5rem;
            }

            p {
                font-size: 1.8rem;
                line-height: 3.5rem;
            }

            ol,
            ul {
                @include media-breakpoint-up(md) {
                    margin-bottom: 40px;

                    li {
                        padding-left: 35px;
                        font-size: 1.8rem;
                        line-height: 3.5rem;
                    }
                }
            }

            ul li::before {
                font-size: 1rem;
            }
        }

        footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 40px;

            .btn {
                margin-top: 0;
            }

            .at-resp-share-element .at-share-btn {
                margin-bottom: 0;

                &:nth-child(n+2) .at-label {
                    display: none;
                }
            }
        }
    }
}
