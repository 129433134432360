//
// === LOADER ===
//

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;

    > div {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;

        &.bounce1 {
            background-color: $lima;
            animation-delay: -.32s;
        }

        &.bounce2 {
            background-color: $supernova;
            animation-delay: -.16s;
        }

        &.bounce3 {
            background-color: $pomegranate;
        }
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}
