//
// === REMODAL ===
//

.remodal-overlay,
.remodal-wrapper {
    top: 52px;

    @include media-breakpoint-up(md) {
        top: 87px;
    }
}

.remodal-overlay {
    background-color: $gallery;
}

.remodal-wrapper {
    padding: 0 15px;
    transition: transform .5s cubic-bezier(.05, .69, .14, 1);

    &.make-way-for-nav {
        transform: translateX(-230px);
    }

    .remodal {
        margin: 0;
        padding: 15px 0 40px;
        background-color: transparent;

        .modal__header,
        .modal__content,
        .modal__footer {
            text-align: left;
        }

        .modal__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding: 20px 30px;
            background-color: $white;

            .h2 {
                margin-bottom: 0;
                font-size: 2.6rem;
            }

            button {
                position: absolute;
                top: 37px;
                right: 30px;
                width: 30px;
                height: 30px;
                margin-left: 10px;
                transform: translateY(-50%);

                i {
                    font-size: 1.4rem;
                }
            }
        }

        .modal__content {
            position: relative;
            background-color: $white;
            box-shadow: 0 3px 0 0 $shadow-seashell;

            .btn {
                width: 100%;
            }
        }

        .modal__footer {
            position: absolute;
            bottom: 0;
            margin: 0 -15px;
            padding-bottom: 0;
        }

        .banner--hero {
            height: 150px;
        }
    }

    @media only screen and (min-width: 641px) {
        .remodal {
            max-width: 768px;
        }
    }

    @include media-breakpoint-up(md) {
        .remodal {
            padding: 80px 0 90px;

            .modal__header {
                padding-left: 40px;
                padding-right: 40px;
                background-color: $ebony-clay;

                .h2,
                button {
                    color: $white;
                }

                .h2 {
                    font-size: 3.1rem;
                }

                button {
                    top: 50%;
                    right: 40px;
                    transform: translateY(-50%);

                    i {
                        font-size: 2.4rem;
                    }
                }
            }

            .modal__content {
                .btn:not(.btn--block) {
                    width: auto;
                }
            }

            .modal__footer {
                margin: 0;
                left: -370px;
                right: -370px;
            }

            .great-succes {
                .banner--hero {
                    height: 310px;
                }

                .h2 {
                    font-size: 4.5rem;
                    line-height: 6.5rem;
                }

                footer {
                    margin-top: 30px;

                    .btn {
                        margin: 0;

                        + .btn {
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }
}
