//
// === BADGES ===
//

.badges {
    display: flex;
    justify-content: space-between;

    a {
        transition: opacity .1s ease-in-out;

        img {
            width: auto;
        }

        &:hover {
            opacity: .75;
        }
    }
}

//
// - Badges in Mobile Navigation -
//

.nav-mobile .badges {
    position: absolute;
    right: 20px;
    bottom: 88px;
    left: 20px;

    a img {
        height: 26px;
    }

    @include media-breakpoint-up(sm) {
        position: relative;
        right: auto;
        bottom: auto;
        left: auto;
        margin-top: 20px;
    }
}

//
// - Badges on Apps page -
//

.section--first--apps,
.section--apps--safe-reporting {
    .badges {
        width: 226px;
        margin-top: 40px;

        a img {
            height: 32px;
        }
    }
}
